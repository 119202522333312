/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it


require('./src/style/global.css')

/*
const masonry = require('./static/masonry.js');

export const onRouteUpdate = (pathobj) => {

    if (pathobj.location.pathname == '/blog') {
        masonry.resizeAllItems();
    }
}
*/