module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"blog":"/opt/build/repo/src/layouts/blogpost.js","default":"/opt/build/repo/src/layouts/blogpost.js"},"extensions":[".mdx",".md"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ben Dod","short_name":"Ben Dod","start_url":"/","background_color":"#ffffff","theme_color":"6600cc","icon":"src/images/favicon-256x256.jpg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3c6caca2a31458f620e1c800d853dd41"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
