// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-content-blog-ninjacodecoercivetyping-md": () => import("./../../../src/content/blog/ninjacodecoercivetyping.md" /* webpackChunkName: "component---src-content-blog-ninjacodecoercivetyping-md" */),
  "component---src-content-blog-reactjsxfromscratch-md": () => import("./../../../src/content/blog/reactjsxfromscratch.md" /* webpackChunkName: "component---src-content-blog-reactjsxfromscratch-md" */),
  "component---src-content-blog-welcome-01-hello-md": () => import("./../../../src/content/blog/welcome/01-hello.md" /* webpackChunkName: "component---src-content-blog-welcome-01-hello-md" */),
  "component---src-content-blog-welcome-02-goodbye-md": () => import("./../../../src/content/blog/welcome/02-goodbye.md" /* webpackChunkName: "component---src-content-blog-welcome-02-goodbye-md" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-test-global-js": () => import("./../../../src/pages/test/global.js" /* webpackChunkName: "component---src-pages-test-global-js" */),
  "component---src-pages-test-test-js": () => import("./../../../src/pages/test/test.js" /* webpackChunkName: "component---src-pages-test-test-js" */)
}

